<template>
  <div class="user-box" style=" position: relative;">
    <s-header :name="$t('LAN.my_info')"></s-header>

    <div class="user-list" >
      <van-cell-group>
        <van-cell :title="$t('LAN.my_info_avatar')" @click="showAvatarAction = true" label="" is-link center="true">
            <template #title>
                <span class="custom-title">{{$t('LAN.avatar')}}</span>
                <van-tag v-if="avatarShowStatus" :type="avatarStatusType">{{avatarStatusLabel}}</van-tag>
            </template>
            <template #value>
                <van-swipe :autoplay="3000" >
                <van-swipe-item >
                    <img  :src="userInfo.avatar" alt="" style="width:100px;height:100px;border-radius: 50%;" />
                </van-swipe-item>
                </van-swipe>
            </template>
        </van-cell>
         <van-cell :title="$t('LAN.account')" :value="userInfo.username" />
      </van-cell-group>
    </div>

  
    <van-action-sheet v-model:show="showAvatarAction" :actions="avatarActions" @select="onAvatarActionSelect" style="position: absolute;bottom: 12px;" :cancel-text="$t('LAN.cancel')" />


    <!-- 裁剪框 -->
    <div>
        <h5-cropper
            class="cropper"
            :option="avatarOption"
            @getbase64Data="getAvatarBase64Data"
            @getFile="getAvatarFile"
        ></h5-cropper>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import sHeader from '@/components/Header'
import { useStore } from 'vuex'
import { Toast, Dialog } from 'vant'
import { useI18n } from 'vue-i18n'
import { ImagePreview } from 'vant';
import { requestSetAvatar, requestGetAvatar } from "@/service/user";
import H5Cropper from 'vue-cropper-h5';
import uploadFile from '@/utils/uploadFile'
import { validateNull } from '@/utils/validate'
import { formate } from '@/utils/string'

export default {

  setup() {
    // 扩展语言
    // const { proxy } = getCurrentInstance();
    // const router = useRouter()
    const store = useStore()
    // const route = useRoute()
    const { t } = useI18n()


    const state = reactive({
        showAvatarAction: false,
        avatarActions: [
            {name:t('LAN.look_avatar'), index:0},
            {name:t('LAN.change_avatar'), index:1}
        ],
        // 头像裁剪配置
        avatarOption: {
            outputType:"jpeg",
            canScale:true,
            ceilbutton:true,
            canMoveBox:true,
            cancelButtonText:t('LAN.cancel'),
            confirmButtonText:t('LAN.confirm')
        },
        avatarUploading: false,
        avatarStatus:0, // 0 取消 1 审核中 2已通过 3拒绝
        avatarStatusType:"success",
        avatarStatusLabel:"",
        avatarShowStatus:false,
        userInfo: {
            username:"",
            avatar:'https://handzone-1259199078.file.myqcloud.com/feedback/2022-10/1666165741095.jpg',
        },
    });

    onMounted(async () => {
        state.userInfo.username = store.getters.username;
        let result = await requestGetAvatar();
        if (validateNull(result.url)) {
            state.userInfo.avatar = 'https://handzone-1259199078.file.myqcloud.com/feedback/2022-10/1666165741095.jpg';
        } else {
            result.url = result.url.replace("http://", "https://")
            state.userInfo.avatar = result.url;
        }
        state.avatarStatus = result.status;
        state.avatarRemarks = {
            "1":t('LAN.shhz0001'),
            "2":t('LAN.shhz0002'),
            "3":t('LAN.shhz0003'),
            "4":t('LAN.shhz0004'),
            "5":t('LAN.shhz0005'),
            "6":t('LAN.shhz0006'),
            "7":t('LAN.shhz0007'),
            "8":t('LAN.shhz0008'),
        }

        if (!validateNull(result.remarks)) {
            Dialog.confirm({
                cancelButtonText: t('LAN.cancel'),
                confirmButtonText: t('LAN.confirm'),
                title: formate(t('LAN.zbm0008'), state.avatarRemarks[result.remarks]),
                closeOnClickOverlay: true,
                confirmButtonColor: "#6e87ea"
            })
        }
        updateAvatarStatus();
    });

    const updateAvatarStatus = () => {
        if (state.avatarStatus == 1) {// 1 审核中
            state.avatarShowStatus = true;
            state.avatarStatusType = "success";
            state.avatarStatusLabel = t('LAN.avatar_reviewing');
        } else if (state.avatarStatus == 3) { // 3拒绝
            state.avatarShowStatus = true;
            state.avatarStatusType = "danger";
            state.avatarStatusLabel = t('LAN.avatar_review_fail');
        }
    }

    const validatorPwd = () => {
        if (state.newPassword.length < 6 || state.newPassword.length > 15) {
            return false;
        }
        return true;
    };

    const onAvatarActionSelect = (item) => {
        if (item.index == 0) {
            ImagePreview(
            {
                images:[state.userInfo.avatar],
                showIndex:false,
            });
        } else if (item.index == 1) {
            document.querySelector('input.upbtn').click();
        }
        state.showAvatarAction = false;
    };

    const getAvatarFile = (file) => {
        if (state.avatarUploading) {
            return;
        }
        let date = new Date()
        let timestamp = date.getTime()
        Toast.loading(t('LAN.loading'));
        state.avatarUploading = true;
        let url = "sdk/avatar/" + store.getters.userId + "-" + timestamp + ".jpg";
        uploadFile(file, function(url) {
            state.avatarUploading = false;
            state.userInfo.avatar = url;
            console.log(url);
            onSetAvatar();
        }, url, 300, 300);
    }

    const onSetAvatar = async () => {
        Toast.loading(t('LAN.sending'));
        state.avatarStatus = 1;
        await requestSetAvatar({
            userId: store.getters.userId,
            username: store.getters.username,
            url: state.userInfo.avatar,
            status: 1
        });
        state.showStatus = true;
        state.avatarStatus = 1;
        updateAvatarStatus();
        Toast(t('LAN.success'));
    }

    return {
        ...toRefs(state),
        validatorPwd,
        onAvatarActionSelect,
        getAvatarFile
    };
  },
  components: {
        sHeader,
        [ImagePreview.Component.name]: ImagePreview.Component,
        H5Cropper,
  },
};
</script>

<style lang="less" scoped>
.user-box {
  height: 100vh;
}

.user-list {
  text-align: left;
}

.cropper {
  width: 0px;
  height: 0px;
  line-height: 0px;
  /* 切记position: relative一定要有 */
  position: relative;
  border-radius: 0px;
  overflow: hidden;
  text-align: center;
  z-index: 10000;
}

</style>